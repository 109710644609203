<template>
  <transition name="modal" appear>
    <!--
  　下の行のだとポップアップウィンドウ外をクリックするとlogin関数が呼ばれる
    <div class="modal modal-overlay" @click.self="$emit('close')">
    -->
    <div class="modal modal-overlay">
      <div class="modal-window">
        <div class="modal-header">
          <h1>ログイン画面</h1>
        </div>
        <div class="modal-content">
          <slot />
        </div>
        <footer class="modal-footer">
          <slot name="footer">
            <button v-if="isLoading" class="button is-info is-smail is-loading">ログイン</button>
            <button
              v-else
              @click="$emit('login')"
              class="button is-info is-smail fa fa-sign-in"
            >ログイン</button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["isLoading"]
};
</script>
<style lang="scss" scoped>
.modal-content {
  width: 100%;
  margin: 0;
}
form div p.has-text-medium {
  margin: 10px 0 5px;
  font-weight: bold;
}
.modal-window h1 {
  font-size: 24px !important;
  font-weight: bold;
}
button.button.is-info.is-smail.fa.fa-sign-in {
  max-width: 250px;
  width: 100%;
  border-radius: 50px;
}
.fa-sign-in:before {
  content: "";
  position: relative;
  left: -15px;
}
p.has-text-medium[data-v-2a183b29] {
  margin: 10px 0 5px;
  font-weight: bold;
}
.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.5);*/
  }
  &-header {
    text-align: center;
  }
  &-window {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    max-width: 500px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.3);
  }

  &-content {
    padding: 10px 20px;
  }

  &-footer {
    padding: 10px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    &-window {
      max-width: 90%;
      padding: 10px;
    }
  }
}
// オーバーレイのトランジション
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s;

  // オーバーレイに包含されているモーダルウィンドウのトランジション
  .modal-window {
    transition: opacity 0.4s, transform 0.4s;
  }
}

// ディレイを付けるとモーダルウィンドウが消えた後にオーバーレイが消える
.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;

  .modal-window {
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>
