<template>
  <div class="Loginwindow" v-if="isLoginModal">
    <section class="is-blue is-fullheight has-text-white has-text-centered">
      <div class="">
        <div class="container">
          <img class="title-image" src="/jalg_logo.png"/>
          <h2 class="subtitle"></h2>
        </div>
      </div>
    </section>
    <!-- コンポーネント LoginForm -->
    <!--    <form v-if="!$store.state.authUser" @submit.prevent="login"> -->
    <LoginForm @login="loginModal" :isLoading="isLoading" v-if="modal">
      <form @submit.prevent="loginModal">
        <!-- default スロットコンテンツ -->
        <p v-if="formError" class="error">{{ formError }}</p>
        <p class="marb10">
          <i>ログイン情報を入力してください。</i>
        </p>
        <div>
          <p class="has-text-medium">メールアドレス：</p>
        </div>
        <div>
          <p class="marb20 control has-icons-left is-small">
            <input
              v-model="formUsername"
              class="input is-info is-rounded"
              type="email"
              name="email"
              placeholder="email address"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-envelope"></i>
            </span>
          </p>
        </div>
        <div>
          <p class="has-text-medium">パスワード:</p>
        </div>
        <div>
          <p class="control has-icons-left is-small">
            <input
              v-model="formPassword"
              class="input is-info is-rounded"
              type="password"
              name="password"
              placeholder="Password"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>
        <!-- /default -->
        <!-- footer スロットコンテンツ -->
        <template slot="footer">
          <!--
        <button type="submit">ログイン</button>
          -->
          <button>ログイン</button>
        </template>
      </form>
    </LoginForm>
  </div>
  <div v-else>{{movecarList()}}</div>
</template>

<script>
import LoginForm from "~/components/loginform.vue";

export default {
  layout: "index",
  components: { LoginForm },
  data() {
    return {
      modal: true,
      message: "",
      formError: null,
      formUsername: "",
      formPassword: "",
      isLoading: false
    };
  },
  methods: {
    async loginModal() {
      try {
        this.isLoading = true;

        if (this.formUsername === "" || this.formPassword === "") {
          this.isLoading = false;
          this.formError = "メールアドレスとパスワードを入力してください";
          return;
        }

        // ログイン
        await this.$store.dispatch("login", {
          username: this.formUsername,
          password: this.formPassword
        });

        // 通知設定を取得
        var notify = await this.$store.dispatch("account_notify", {
          account_id: this.$store.state.authUser
        });

        this.formUsername = "";
        this.formPassword = "";
        this.formError = "";

        this.modal = false;
        this.$router.push("/car/car_list");
        //        this.listform = true;

      } catch (e) {
        // エラー時はローディング解除
        this.isLoading = false;
        this.formError = e.message;
      }
    },
    closeModal() {
      this.modal = false;
    },
    movecarList() {
      this.$router.push("/car/car_list");
    },
    isLoginModal() {
      return !this.$store.state.authUser || this.modal;
    }
  }
};
</script>

<style lang="scss" scoped>
form input {
  width: 400px;
}
.title-image {
  width: 300px;
  margin-top: 10%;
}

.Loginwindow{
  background-image: url("/bg7.jpg");
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .loginttl {
    font-size: 1.5rem;
  }
  .modal-content {
    margin-left: 0;
  }
}
</style>